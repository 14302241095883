import { DefaultLayout } from "../components/layout/twentyfour/default";
import { AggregatorImportModule } from "../modules/aggregator/aggregator-import";

export function AggregatorImportPage() {
  return (
    <DefaultLayout>
      <AggregatorImportModule />
    </DefaultLayout>
  );
};
