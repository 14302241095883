import { Grid, Stack } from "@mui/material";

import { DataTable } from "components/commons/data-table/data-table";
import { DataTableControl } from "components/commons/data-table/data-table-control";
import { DataTablePagination } from "components/commons/data-table/data-table-pagination";
import Intro from "components/commons/intro/intro";
import { useNavigate } from "react-router-dom";
import { getDsrSourceMapList } from "../../apis/dsr.api";
import { DataTableDateRange } from "../../components/commons/data-table/data-table-date-range";
import { InsertSourceMapDialog } from "./insert-source-map-dialog";
import { useColumns } from "./source-map-list-column";
import { SourceMapFilter } from "./source-map-list-filter";
import { useInitialFilterState } from "./source-map-list-filter.state";

export type DsrSourceMapModuleProps = {};

export function DsrSourceMapListModule(props: DsrSourceMapModuleProps) {
  const navigate = useNavigate();
  const columns = useColumns();
  const initialFilterState = useInitialFilterState();

  return (
    <>
      <Intro title="DSR Source Map" subtitle="Summary of DSR Source Map" />
      <DataTableControl
        columns={columns}
        listFn={getDsrSourceMapList}
        initialApiProps={{ query: initialFilterState }}
        autoCommit={false}
      >
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs>
              <Stack direction="row" spacing={1}>
                <SourceMapFilter />
                <DataTableDateRange showDateText />
              </Stack>
            </Grid>
            <Grid item>
              <Stack direction="row" alignItems="flex-end">
                <InsertSourceMapDialog />
              </Stack>
            </Grid>
          </Grid>

          <DataTable
            initialTableState={{
              columnVisibility: {
                batchId: false,
              },
            }}
          />
          <DataTablePagination />
        </Stack>
      </DataTableControl>
    </>
  );
}
