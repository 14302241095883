import moment from "moment-timezone";
import { GetAggregatedSummaryByStatusQueryApi } from "../../apis/aggregator.api";
import { useAppContext } from "../../hooks/useApp";

export function useInitialFilterState() {
  const { firebase, initState } = useAppContext();

  const previousState = initState.getState("aggregated-filter");
  const endDate = moment().tz("Asia/Manila").endOf("day");
  const startDate = moment(endDate).subtract(3, "days").startOf("day");

  const initialFilterState: GetAggregatedSummaryByStatusQueryApi = {
    searchKey: "",
    page: 1,
    perPage: 10,
    startDate: startDate.toISOString(true),
    endDate: endDate.toISOString(true),
  };

  const keys: string[] = ["searchKey"];
  Object.keys(previousState || {}).forEach((key) => {
    if (keys.includes(key)) {
      (initialFilterState as any)[key] = previousState[key] as any;
    }
  });

  return initialFilterState;
}
