import { ColumnDef } from "@tanstack/react-table";
import { DisplayDate } from "../../components/commons/data-display/DisplayDate";
import { DisplayDateOnly } from "../../components/commons/data-display/DisplayDateOnly";

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export function useColumns() {
  const columns: ColumnDef<unknown>[] = [
    {
      id: "createdAt",
      accessorKey: "createdAt",
      header: "Created At",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: "sourceMapId",
      accessorKey: "sourceMapId",
      header: "Source Map ID",
    },
    {
      id: "stationCode",
      accessorKey: "stationCode",
      header: "Station Code",
    },
    {
      id: "dataSource",
      accessorKey: "dataSource",
      header: "Data Source",
    },
    {
      id: "startDate",
      accessorKey: "startDate",
      header: "Start Date",
      cell: (info) => (
        <DisplayDateOnly>{info.getValue() as string}</DisplayDateOnly>
      ),
    },
    {
      id: "endDate",
      accessorKey: "endDate",
      header: "End Date",
      cell: (info) => (
        <DisplayDateOnly>{info.getValue() as string}</DisplayDateOnly>
      ),
    },
  ];

  return columns;
}
