import { MapOutlined } from "@mui/icons-material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DescriptionIcon from "@mui/icons-material/Description";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import PeopleIcon from "@mui/icons-material/People";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import * as paths from "./enums/path";
import { Role } from "./enums/roles";
import { AdminPage } from "./pages/admin.page";
import { AggregatorImportPage } from "./pages/aggregator-import.page";
import { AggregatedListPage } from "./pages/aggregator-list.page";
import { AggregatedSummaryPage } from "./pages/aggregator-summary.page";
import { AuthPage } from "./pages/auth.page";
import { CustomerListPage } from "./pages/customer-list.page";
import { DsrImportPage } from "./pages/dsr-import.page";
import { DsrRecordsPage } from "./pages/dsr-records.page";
import { DsrSummaryPage } from "./pages/dsr-summary.page";
import { ExpensePage } from "./pages/expense.page";
import { SettlementImportPage } from "./pages/settlement-import.page";
import { SettlementListPage } from "./pages/settlement-list.page";
import { SourceMapListPage } from "./pages/source-map-list.page";
import { StationMasterPage } from "./pages/station-master.page";
import { UserProfilePage } from "./pages/user-profile.page";

export type UrlPath = {
  path: string; // path, this is not the absolute path
  element?: JSX.Element; // page to show for the path
  label?: string; // friendly name to use in sidebar
  icon?: JSX.Element; // icon to use if it is used if menu = true
  roles?: Role[]; // does the page require specific role?
  protected?: boolean; // does it require user to login?
  menu?: boolean; // show in sidebar
  sub?: UrlPath[]; // for children path
};

export const routes: UrlPath[] = [
  {
    path: paths.Aggregated,
    label: "Aggregated",
    icon: <FactCheckIcon />,
    protected: true,
    roles: [Role.E2E],
    sub: [
      {
        path: paths.Import,
        label: "Aggregated",
        element: <AggregatorImportPage />,
        icon: <UploadFileIcon />,
        protected: true,
        roles: [Role.E2E],
      },
      {
        path: paths.Summary,
        label: "Aggregated Summary",
        element: <AggregatedSummaryPage />,
        protected: true,
        roles: [Role.E2E],
        icon: <FactCheckIcon />,
        menu: true,
      },
      {
        path: paths.List,
        label: "Aggregated List",
        element: <AggregatedListPage />,
        protected: true,
        roles: [Role.E2E],
        sub: [
          {
            path: ":batchId",
            label: "Aggregated List",
            element: <AggregatedListPage />,
            protected: true,
            roles: [Role.E2E],
          },
        ],
      },
    ],
  },
  {
    path: paths.Settlement,
    label: "Non-cash settlement report",
    element: <SettlementListPage />,
    icon: <MoneyOffIcon />,
    protected: true,
    roles: [Role.AR],
    menu: false, // temporarily disabled
    sub: [
      {
        path: paths.Import,
        label: "Import non-cash settlement",
        element: <SettlementImportPage />,
        protected: true,
        roles: [Role.AR],
      },
    ],
  },
  {
    path: paths.Dsr,
    label: "DSR Summary",
    protected: true,
    roles: [Role.SH, Role.E2E],
    element: <DsrSummaryPage />,
    icon: <DescriptionIcon />,
    menu: true,
    sub: [
      {
        path: paths.Records,
        label: "DSR Records",
        protected: true,
        roles: [Role.E2E],
        element: <DsrRecordsPage />,
        icon: <FileCopyIcon />,
        menu: true,
      },
      {
        path: paths.Import,
        label: "Import DSR file",
        element: <DsrImportPage />,
        icon: <DriveFolderUploadIcon />,
        protected: true,
        menu: true,
        roles: [Role.SH],
      },
    ],
  },
  {
    path: paths.User,
    label: "User",
    protected: true,
    sub: [
      {
        path: paths.Profile,
        label: "Profile",
        element: <UserProfilePage />,
        roles: [Role.LOGGEDIN],
        protected: true,
      },
    ],
  },
  {
    path: paths.Auth,
    label: "Auth",
    element: <AuthPage />,
    protected: false,
  },
  {
    path: paths.Customer,
    label: "Customers",
    icon: <PeopleIcon />,
    element: <CustomerListPage />,
    roles: [Role.SH, Role.E2E, Role.AR],
    menu: true,
    protected: true,
  },
  {
    path: paths.Expense,
    label: "Expense",
    icon: <ReceiptLongIcon />,
    element: <ExpensePage />,
    roles: [Role.ADMIN],
    menu: true,
    protected: true,
  },
  {
    path: paths.StationMaster,
    label: "Station Master",
    icon: <LocalGasStationIcon />,
    element: <StationMasterPage />,
    roles: [Role.ADMIN],
    menu: true,
    protected: true,
  },
  {
    path: paths.Admin,
    label: "Admin",
    icon: <AdminPanelSettingsIcon />,
    element: <AdminPage />,
    roles: [Role.ADMIN],
    menu: true,
    protected: true,
  },
  {
    path: paths.SourceMap,
    label: "DSR Source Map",
    icon: <MapOutlined />,
    element: <SourceMapListPage />,
    roles: [Role.E2E],
    menu: true,
    protected: true,
  },
];
