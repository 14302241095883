import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Stack,
} from '@mui/material';
import { importAggregator } from 'apis/aggregator.api';
import { FileUpload } from 'components/commons/file-upload/file-upload';
import Intro from 'components/commons/intro/intro';
import { useSwal } from 'hooks/useSwal';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BackLink } from '../../components/commons/back-link/back-link';
import Path from '../../enums/path';

export function AggregatorImportModule() {
  const swal = useSwal();

  const [submitResponse, setSubmitResponse] = useState<any>();
  const [isloading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data: any) => {
    let confirmation = false;
    let errorReason = '';
    if (submitResponse?.totalMatchError > 0) {
      errorReason = await swal
        .fire({
          title: 'You are upisloading a DSR with identified errors!',
          text: 'Please indicate reason/s for proceeding below',
          input: 'textarea',
        })
        .then((result: any) => {
          if (result.isConfirmed) {
            return result.value;
          }
          return '';
        });

      if (errorReason !== '') {
        confirmation = true;
      }
    } else {
      const { isConfirmed } = await swal.showConfirm({
        title: 'Confirmation',
        text: 'Are you sure you want to submit?. Please click Confirm button to submit your request.',
      });
      confirmation = isConfirmed;
    }

    if (!confirmation) {
      return false;
    }

    try {
      setSubmitResponse(null);
      setIsLoading(true);
      const res = await importAggregator({
        data: data.file[0],
        errorReason,
      }).finally(() => setIsLoading(false));
      const totalError = res?.totalError ?? 0;
      const totalMatchError = res?.totalMatchError ?? 0;
      const totalInsert = res?.totalInsert ?? 0;

      let customErrorMessage = '';
      if (totalInsert > 0) {
        customErrorMessage = `${totalInsert} row(s) successfully saved; `;
      }
      if (totalMatchError > 0) {
        customErrorMessage += `${totalMatchError} row(s) settlement not found; `;
      }
      if (totalError > 0) {
        customErrorMessage += `${totalError} row(s) with other error(s); `;
      }

      if (
        customErrorMessage !== '' &&
        (totalMatchError > 0 || totalError > 0)
      ) {
        swal.showWarning({
          title: 'Imported w/ Error',
          text: customErrorMessage,
        });
      } else {
        swal.showSuccess({
          title: 'Success!',
          text: 'Successfully imported.',
          preConfirm: () => {
            setSubmitResponse(null);
            return reset();
          },
        });
      }

      setSubmitResponse(res);
    } catch (e: any) {
      if (e?.response?.data?.message) {
        swal.showError({
          title: 'Error!',
          text: e?.response?.data?.message,
          preConfirm: () => {
            return onSubmit(data);
          },
        });
      }
    }
  };

  return (
    <>
      <BackLink list={[{ text: 'Summary', path: Path.AggregatedSummary }]} />
      <Intro title="Import" subtitle="Import Validated DSR Sales" />
      <Card variant="outlined" component={'div'}>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <FormControl>
                <Controller
                  name="file"
                  control={control}
                  rules={{ required: 'Select File is required' }}
                  render={({ field }) => (
                    <FileUpload
                      multiple={false}
                      buttonText="Select File"
                      value={watch('file')}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      noDrag={true}
                      buttonProps={{
                        variant: 'outlined',
                        color: 'primary',
                      }}
                      sx={{ textAlign: 'center' }}
                      accept={{
                        'application/vnd.ms-excel': ['.xls'],
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                          ['.xlx'],
                        'text/csv': ['.csv'],
                      }}
                    />
                  )}
                />
                <FormHelperText error={true}>
                  <>{errors?.file?.message}</>
                </FormHelperText>
              </FormControl>
              {submitResponse?.errorList && (
                <div>
                  <FormHelperText error={true}>
                    <strong>Found the following errors</strong>
                  </FormHelperText>
                  <ul>
                    {submitResponse.errorList.map(
                      (item: any, index: number) => (
                        <li key={index}>{typeof item === 'string' ? item : item.message}</li>
                      )
                    )}
                  </ul>
                  <a href={submitResponse?.linkAttached}>
                    {submitResponse?.fileName}
                  </a>
                </div>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isloading}
              >
                {isloading
                  ? 'Submitting...'
                  : submitResponse?.totalMatchError > 0
                    ? 'Submit with errors'
                    : 'Submit'}
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </>
  );
}
