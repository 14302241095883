import {
  Button,
  Checkbox,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { format as formatDate } from "date-fns";
import moment from "moment";
import React, { useState } from "react";
import { createDsrSourceMap, dsrSourceMapProps } from "../../apis/dsr.api";
import { LocationAutocomplete } from "../../components/commons/form/autocomplete/location-autocomplete";
import { FormController } from "../../components/commons/form/controller/form-controller";
import { DatePicker } from "../../components/commons/form/date-picker/date-picker";
import { Select } from "../../components/commons/form/select/select";
import { DataSourceEmun } from "../../enums/data-source";
import { useSwal } from "../../hooks/useSwal";

export function InsertSourceMapDialog() {
  // const formController = useFormController();
  const swal = useSwal();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const momentStart = React.useMemo(
    () => moment(startDate).tz("Asia/Manila"),
    [startDate]
  );
  const momentEnd = React.useMemo(
    () => moment(endDate).tz("Asia/Manila"),
    [endDate]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = React.useCallback(
    (data: any) => {
      if (data.startDate !== momentStart.toISOString(true)) {
        setStartDate(data.startDate);
      }
      if (data.endDate !== momentEnd.toISOString(true)) {
        setEndDate(data.endDate);
      }
    },
    [momentStart, momentEnd]
  );

  React.useEffect(() => {}, [onChange]);

  const onSubmit = async (data: dsrSourceMapProps) => {
    try {
      setLoading(true);
      const record = {
        stationCode: Object(data.stationCode)["stationCode"],
        dataSource: data.dataSource,
        startDate: !data.startDate
          ? formatDate(String(new Date()), "yyyy-MM-dd")
          : data.startDate,
        endDate: isChecked
          ? !data.endDate
            ? ""
            : data.endDate
          : formatDate(String(new Date()), "yyyy-MM-dd"),
      };
      await createDsrSourceMap(record);
      swal.showSuccess({
        title: "Success!",
        text: "Source Map successfully saved.",
        preConfirm: () => {
          handleClose();
        },
      });
    } catch (e: any) {
      swal.showError({
        title: "Error!",
        text: e?.response?.data?.message,
        preConfirm: () => {
          return onSubmit(data);
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button onClick={handleClickOpen} variant="contained">
        Add Source Map
      </Button>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 1 }}
      >
        <DialogTitle>Add Source Map</DialogTitle>
        <DialogContent>
          <FormController
            onSubmit={onSubmit}
            useFormProps={
              {
                // resolver: yupResolver(InsertSourceMapFormValidator),
              }
            }
          >
            <Stack spacing={2} mt={2}>
              <LocationAutocomplete
                name="stationCode"
                label="Station Code"
                textFieldProps={{ required: true }}
              />{" "}
            </Stack>
            <Stack spacing={2} mt={2}>
              <Select
                name="dataSource"
                label="Data Source"
                options={["", ...Object.values(DataSourceEmun)]}
                selectProps={{ sx: { minWidth: "100px" } }}
              />
            </Stack>
            <Stack spacing={2} mt={2}>
              <DatePicker
                name="startDate"
                label="Start Date"
                datePickerProps={{
                  maxDate: momentEnd,
                }}
                dateModifier={(d) => d.startOf("day")}
              />
            </Stack>
            <Stack spacing={2} mt={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                }
                label="No End Date"
              />
              <DatePicker
                disabled={isChecked}
                name="endDate"
                label="End Date"
                datePickerProps={{
                  maxDate: momentEnd,
                }}
                dateModifier={(d) => d.startOf("day")}
              />
            </Stack>

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              mt={4}
            >
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Save
              </Button>
            </Stack>
          </FormController>
        </DialogContent>
      </Dialog>
    </>
  );
}
