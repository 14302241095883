export const Slash = '/';
/** @deprecated */
export const Aggregator = 'aggregator';
export const Aggregated = 'aggregated';
export const Transaction = 'transaction';
export const Summary = 'summary';
export const Settlement = 'settlement';
export const Dsr = 'dsr';
export const User = 'user';
export const Auth = 'auth';
export const Import = 'import';
export const Shift = 'shift';
export const Profile = 'profile';
export const List = 'list';
export const Customer = 'customer';
export const Records = 'records';
export const Expense = 'expense';
export const StationMaster = 'station-master';
export const Admin = 'admin';
export const SourceMap = 'source-map';

const Path = {
  Slash,

  /** @deprecated */
  Aggregator: `/${Aggregator}`,
  /** @deprecated */
  AggregatorImport: `/${Aggregator}/${Import}`,
  /** @deprecated */
  AggregatorAggregated: `/${Aggregator}/${Aggregated}`,
  /** @deprecated */
  AggregatorTransactionSummary: `/${Aggregator}/${Transaction}/${Summary}`,
  /** @deprecated */
  AggregatorTransaction: `/${Aggregator}/${Transaction}`,

  Aggregated: `/${Aggregated}`,
  AggregatedImport: `/${Aggregated}/${Import}`,
  AggregatedSummary: `/${Aggregated}/${Summary}`,
  AggregatedList: `/${Aggregated}/${List}`,

  Settlement: `/${Settlement}`,
  SettlementImport: `/${Settlement}/${Import}`,
  SettlementList: `/${Settlement}/${List}`,

  Dsr: `/${Dsr}`,
  DsrRecords: `/${Dsr}/${Records}`,
  DsrImport: `/${Dsr}/${Import}`,
  User: `/${User}`,
  UserProfile: `/${User}/${Profile}`,

  Auth: `/${Auth}`,

  Customer: `/${Customer}`,

  Expense: `/${Expense}`,
  StationMaster: `/${StationMaster}`,
  Admin: `/${Admin}`,

  SourceMap: `/${Dsr}/${SourceMap}`,
};

export default Path;
