import { DefaultLayout } from "../components/layout/twentyfour/default";
import { DsrSourceMapListModule } from "../modules/source-map/source-map-list.module";

export function SourceMapListPage() {
  return (
    <DefaultLayout>
      <DsrSourceMapListModule />
    </DefaultLayout>
  );
}
